<template>
    <div class="recommend h100" style="background: #f9f9f9; overflow: hidden;">
        <a-spin :spinning="spinning">
            <div class="container h100">
                <div class="main_title home_main_title">Recommended <a-button @click="back">Back</a-button></div>
                <div class="recommend_list">
                    <a-empty v-if="recommendList.length == 0"></a-empty>
                    <a-row :gutter="10" v-else>
                    <a-col :span="8" v-for="(recommend, idx) in recommendList" :key="idx">
                        <div class="recommend_box">
                                <div class="recommend_body">
                                    <div class="recommend_name">{{recommend.recommended_name}}</div>
                                    <div class="recommend_feature textarea_ellipsis">{{recommend.recommended_feature}}</div>
                                </div>
                                <div class="recommend_image">
                                <img class="img" :src="recommend.image">
                                </div>
                                <div class="recommend_body">
                                    <div class="recommend_price">
                                        <span class="name">Total price</span>
                                        <span class="value">${{recommend.recommended_price}}</span>
                                    </div>
                                </div>
                                <div class="recommend_bottom">
                                    <div class="region">
                                        <span class="name">Region</span>
                                        <span class="region_name">{{recommend.region_name}}</span>
                                    </div>
                                    <a-button type="primary" class="primary btn" @click="go(recommend)">Go</a-button>
                                </div>
                        </div>
                    </a-col>
                    <a-col :span="24" style="text-align: center">
                        <a-button class="more" v-if="moreStatus">Loading more</a-button>
                    </a-col>
                    </a-row>
                </div>
            </div>
        </a-spin>
    </div>
</template>
<script>
import { getRecommendedList } from '@/apis/home/recommended'
import commonMixins from '@/mixins/commonMixins'
export default {
    name: 'Recommend',
    mixins: [commonMixins],
    data(){
        return {
            recommendList: [],
            moreStatus: false,
            spinning: false,
        }
    },
    methods: {
        go(recommended){
            const bill = this.$route.query.bill
            const cost = recommended.recommended_price
            this.$router.push({path: '/recommended_detail', query: {recommended_id: recommended.recommended_id, bill, cost, source: 0}})
        },
        async getRecommendedListHandler(){
            const query = this.$route.query
            this.spinning = true
            const res = await getRecommendedList(query)
            this.spinning = false
            if(res){
                this.recommendList = res.list.rows
            }
        }
    },
    created(){
        this.getRecommendedListHandler()
    }
}
</script>
<style lang="stylus" scoped>
.recommend_list
    height calc(100% - 55px)
    overflow auto
    overflow-x hidden
    .recommend_box
        background #fff
        box-shadow 0 10px 10px 0 rgba(0,0,0,.05)
        cursor pointer
        border-radius 15px
        transition .5s
        &:hover
           box-shadow 0 10px 10px 0 rgba(0,0,0,.07)
        .recommend_image
           border-radius 15px 15px 0 0
           height 160px
           text-align center
           padding 10px
           .img
              object-fit cover
              height 160px
        .recommend_body
            padding 10px 20px
            .recommend_name
                font-size 22px
                overflow hidden
                text-overflow ellipsis
                white-space nowrap                
            .recommend_price
                display flex
                flex-direction column
        .name
            color #abb1bf
        .value
            font-size 22px
            color #43af52
        .recommend_bottom
            border-top 1px solid #eee
            padding 5px 20px 10px 20px
            display flex
            justify-content space-between
            align-items center
            .region_name
                font-size 16px
                color #000
                display flex
    .more
        margin-top 20px        
</style>