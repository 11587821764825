import request from '@/request'


export const getRecommendedList = (data = {}) => {
  return request({
    url: '/home/get_recommended_list',
    method: 'get',
    params: data
  })
}
export const getRecommendedInfo = (data = {}) => {
  return request({
    url: '/home/get_recommended_info',
    method: 'get',
    params: data
  })
}